import useStyles from "./Holder.styles"
import React from "react"
import {
    Box, Typography
} from "@mui/material"

const HolderElement = ({
    id, src, isDesktop, alt, title, text
}) => {
    const classes = useStyles()

    return (
        <Box className={classes.outerWrapper}>
            <Box id={id} className={classes.holderWrapper}>
                <img
                    src={src}
                    alt={alt}
                    style={{
                        width: isDesktop ? "446px" : "100%",
                        height: isDesktop ? "340px" : "auto",
                        objectFit: "cover"
                    }}
                />
                <Box className={classes.holderTextBox}>
                    <Typography className={classes.blackH3Roboto} variant="h3">
                        {title}
                    </Typography>
                    <Typography className={classes.blackArialText}>
                        {text}
                    </Typography>
                </Box>
            </Box>

        </Box>
    );
}

export default HolderElement
