import { useIsUserAuthorized, useUserAuthToken } from "./userHooks";
import { selectById } from "../adapter/priceAdapter";
import { useGetPricesQuery } from "../services/priceApi";
import { useSelector } from "react-redux";

const usePrice = id => useSelector(state => selectById(state, id))

/**
 * Use this hook to load the prices into the price adapter.
 * @param {*} ids The ids of the products we fetch the price for.
 * @returns The prices for the specified products.
 */
export const useLoadPrices = ids => {
    const authToken = useUserAuthToken();
    const isAuthorized = useIsUserAuthorized();
    const { data: prices } = useGetPricesQuery(
        {
            authToken,
            ids
        },
        { skip: !isAuthorized || ids.length <= 0 }
    );
    return prices;
}

export default usePrice;
