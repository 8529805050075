// eslint-disable-next-line import/no-cycle
import { CustomTextField } from ".."
import { Chip, CircularProgress, Autocomplete } from "@mui/material"

import React from "react"

function AutocompleteSearch(props) {
    const {
        selectedOptions,
        multiple,
        label,
        placeholder,
        onChange,
        loading,
        ...remainingProps
    } = props

    return (
        <Autocomplete
            id="autocomplete-search"
            getOptionLabel={option => option.name}
            value={(multiple) ? selectedOptions : (selectedOptions?.[0] ?? null)}
            renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
                <Chip
                    label={option.name}
                    {...getTagProps({ index })}
                />
            ))}
            renderInput={params => (
                <CustomTextField
                    {...params}
                    label={label}
                    placeholder={placeholder}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} marginRight="16px" /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        )
                    }}
                />
            )}
            {...remainingProps}
            onChange={(event, newSelectedOptions) => {
                const newSelectedOptionsArray = (
                    (multiple)
                        ? newSelectedOptions
                        : [newSelectedOptions]
                )
                if (onChange) { onChange(event, newSelectedOptionsArray) }
            }}
        />
    )
}

export default AutocompleteSearch
