import { desktopBreakpoint, mobileMenuBreakpoint } from "../../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    outerWrapper: {
        flex: "0 1 446px",
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            flex: "1 1 auto"
        }
    },
    holderWrapper: {
        display: "flex",
        flex: "0 1 446px",
        height: "100%",
        flexDirection: "column",
        overflow: "hidden",
        borderRadius: 10,
        boxShadow: "0px 0px 20px 0px rgba(45, 45, 45, 0.1)"
        // [theme.breakpoints.down(mobileMenuBreakpoint)]: {
        //     height: "auto"
        // }
    },
    holderTextBox: {
        flex: "1 1 auto",
        backgroundColor: "#FFFFFF",
        background: "#FFF",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        textAlign: "center",
        [theme.breakpoints.down(desktopBreakpoint)]: {
            height: "auto"
        }
    },
    blackH3Roboto: {
        color: "#2D2D2D",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "32px" /* 160% */,
        marginBottom: "5px",
        [theme.breakpoints.down(desktopBreakpoint)]: {
            fontSize: "16px",
            fontWeight: "500px",
            lineHeight: "normal"
        }
    },
    blackArialText: {
        color: "#2D2D2D",
        fontFamily: "Arial",
        fontSize: "17px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "24px", /* 141.176% */
        letterSpacing: "0.5px",
        [theme.breakpoints.down(desktopBreakpoint)]: {
            fontSize: "14px",
            lineHeight: "20px"/* 142.857% */
        }
    }
}))
