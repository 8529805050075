import theme from "../../theme"
import { desktopBreakpoint, mobileBreakpoint, mobileMenuBreakpoint } from "../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(() => ({
    main: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    },
    section1: {
        width: "auto",
        height: "auto",
        backgroundColor: "#FFF",
        [theme.breakpoints.down(desktopBreakpoint)]: {
            width: "80%"
        },
        [theme.breakpoints.down(mobileBreakpoint)]: {
            width: "100%"
        },
        [theme.breakpoints.between(mobileBreakpoint, mobileMenuBreakpoint)]: {
            width: "100%",
            paddingLeft: "0%",
            paddingRight: "0%"
        }
    },
    section1Wrapper: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 40,
        marginBottom: 40
    },
    section2: {
        width: "80%",
        height: "auto",
        flexShrink: 0,
        backgroundColor: "#FFF",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        paddingLeft: "10%",
        paddingRight: "10%",
        paddingBottom: "75px",
        gap: 50,
        [theme.breakpoints.between(mobileBreakpoint, mobileMenuBreakpoint)]: {
            width: "100%",
            paddingLeft: "0%",
            paddingRight: "0%"
        }
    },
    section3: {
        backgroundColor: "#F8F8F8",
        width: "100%",
        paddingTop: "150px",
        paddingBottom: "150px"
    },
    blackH2: {
        color: "#2D2D2D",
        fontFamily: "Rubik",
        fontSize: "30px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "40px" /* 133.333% */,
        [theme.breakpoints.down(desktopBreakpoint)]: {
            textAlign: "center",
            fontSize: "20px",
            lineHeight: "26.8px"
        },
        [theme.breakpoints.down(mobileBreakpoint)]: {
            textAlign: "start"
        }
    },
    firstBlackH2: {
        color: "#2D2D2D",
        fontFamily: "Rubik",
        fontSize: "30px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "40px" /* 133.333% */,
        [theme.breakpoints.down(desktopBreakpoint)]: {
            textAlign: "center",
            fontSize: "20px",
            lineHeight: "26.8px"
        },
        [theme.breakpoints.down(mobileBreakpoint)]: {
            textAlign: "center"
        }
    },
    firstBlackText: {
        color: "#2D2D2D",
        fontFamily: "Arial",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "20px", /* 142.857% */
        letterSpacing: "0.5px",
        [theme.breakpoints.down(desktopBreakpoint)]: {
            textAlign: "center"
        },
        [theme.breakpoints.down(mobileBreakpoint)]: {
            textAlign: "center"
        }
    },
    blackText: {
        color: "#2D2D2D",
        fontFamily: "Arial",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "20px", /* 142.857% */
        letterSpacing: "0.5px",
        [theme.breakpoints.down(desktopBreakpoint)]: {
            textAlign: "center"
        },
        [theme.breakpoints.down(mobileBreakpoint)]: {
            textAlign: "start"
        }
    },
    logo: {
        display: "flex",
        width: "108px",
        height: "54px",
        padding: "8px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        flexShrink: 0
    },
    blackH3: {
        color: "#332D2D",
        /* M3/title/medium */
        fontFamily: "Arial",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "24px", /* 133.333% */
        letterSpacing: "0.15px",
        textAlign: "center"
    },
    box: {
        width: "1080px",
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.between(mobileBreakpoint, mobileMenuBreakpoint)]: {
            width: "auto"
        },
        [theme.breakpoints.down(mobileBreakpoint)]: {
            width: "100%"
        }
    },
    section2ProclipWrapper: {
        display: "flex",
        gap: "46px",
        paddingTop: "20px",
        flexDirection: "row",
        [theme.breakpoints.between(mobileBreakpoint, mobileMenuBreakpoint)]: {
            width: "100%",
            alignItems: "center",
            paddingLeft: "0%",
            paddingRight: "0%"
        },
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            flexDirection: "column"
        }
    },
    image: {
        objectFit: "cover",
        [theme.breakpoints.between(mobileBreakpoint, mobileMenuBreakpoint)]: {
            width: "100%",
            height: "auto"
        },
        [theme.breakpoints.down(mobileBreakpoint)]: {
            width: "100%"
        }
    },
    brandListTitel: {
        color: "#2D2D2D",
        textAlign: "center",
        fontFamily: "Rubik",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "32px" /* 160% */
    },
    brandListText: {
        whiteSpace: "pre-wrap",
        color: "#2D2D2D",
        textAlign: "center",
        /* M3/body/large */
        fontFamily: "Arial",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "24px", /* 133.333% */
        letterSpacing: "0.5px"
    },
    hero: {
        position: "relative",
        width: "100%"
    },
    selectorWrapper: {
        display: "grid",
        gridTemplateColumns: "250px",
        justifyContent: "center",
        gap: "8px",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            gridTemplateColumns: "auto-fill"
        }
    }
}))
