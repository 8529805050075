import MobileDrawer from "./MobileDrawer/MobileDrawer"
import { strings as Localization } from "../../../lib/Localization"
import { useLanguage } from "../../../lib/hooks/languageHooks"
import { useIsUserAuthorized } from "../../../redux/hook/userHooks"
// eslint-disable-next-line import/no-cycle
import useStyles from "../styles"
/* eslint-disable import/no-cycle */
import sweFlag from "../../../assets/swe.png"
import engFlag from "../../../assets/eng.svg"
import useCookiesConsent from "../../../redux/hook/gdprHooks"
import { useSearchQuery } from "../../../redux/hook/searchHooks"
import NavbarMenu from "../NavbarMenu/NavbarMenu"
import {
    Badge, Box, Button, Tooltip
} from "@mui/material"
import { ShoppingCart } from "@mui/icons-material"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

function MobileSearchArea({ toggleShowSearch }) {
    const classes = useStyles()
    const language = useLanguage()
    const isAuthorized = useIsUserAuthorized()
    const cart = useSelector(state => state.cart)
    const otherLang = language === "sv" ? "en" : "sv"
    const changeLanguage = () => window.location.pathname.replace(language, otherLang)
    const acceptedCookies = useCookiesConsent()
    const search = useSearchQuery()

    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    useEffect(() => {
        toggleShowSearch(false)
    }, [search])

    return (
        <Box className={classes.mobileSearchArea}>
            <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                {!isAuthorized && (
                    <Button
                        id="login"
                        style={{ backgroundColor: !acceptedCookies ? "lightgray" : "transparent", border: "none" }}
                        to={`/${language}/signin`}
                        component={Link}
                        aria-label="Log in"
                        disabled={!acceptedCookies}
                    >
                        <span style={{ marginRight: "6px" }}>{Localization.logIn}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
                            <g clipPath="url(#clip0_2775_790)">
                                <path d="M11.7272 10.9999C14.6444 10.9999 17.0072 8.63714 17.0072 5.71994C17.0072 2.80274 14.6444 0.439941 11.7272 0.439941C8.81004 0.439941 6.44724 2.80274 6.44724 5.71994C6.44724 8.63714 8.81004 10.9999 11.7272 10.9999ZM11.7272 13.6399C8.20284 13.6399 1.16724 15.4087 1.16724 18.9199V21.5599H22.2872V18.9199C22.2872 15.4087 15.2516 13.6399 11.7272 13.6399Z" fill="#2D2D2D" />
                            </g>
                            <defs>
                                <clipPath id="clip0_2775_790">
                                    <rect width="22" height="22" fill="white" transform="translate(0.727295)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </Button>
                )}
                {isAuthorized && (
                    <NavbarMenu />
                )}
                <Tooltip title={Localization.toggleLanguage}>
                    <Button
                        id="languageBtn"
                        component={Link}
                        to={changeLanguage()}
                        aria-label="Toggle language"
                        style={{ padding: "0px 0px", minWidth: "38px" }}
                    >
                        <img
                            src={otherLang === "en" ? engFlag : sweFlag}
                            alt={otherLang === "en" ? "us flag" : "swe flag"}
                            className={classes.flag}
                        />
                    </Button>
                </Tooltip>
            </Box>
            <Box className={classes.searchArea}>
                <Button
                    aria-label="Search"
                    className={classes.removeButtonStandardStyling}
                    onClick={() => toggleShowSearch()}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                        <path d="M18.4086 16.6309H17.4895L17.1638 16.3159C18.3039 14.9859 18.9902 13.2593 18.9902 11.3809C18.9902 7.19261 15.605 3.79761 11.4287 3.79761C7.25246 3.79761 3.86725 7.19261 3.86725 11.3809C3.86725 15.5693 7.25246 18.9643 11.4287 18.9643C13.3016 18.9643 15.0233 18.2759 16.3495 17.1326L16.6636 17.4593V18.3809L22.4801 24.2026L24.2134 22.4643L18.4086 16.6309ZM11.4287 16.6309C8.5321 16.6309 6.19386 14.2859 6.19386 11.3809C6.19386 8.47594 8.5321 6.13094 11.4287 6.13094C14.3254 6.13094 16.6636 8.47594 16.6636 11.3809C16.6636 14.2859 14.3254 16.6309 11.4287 16.6309Z" fill="#2D2D2D" />
                    </svg>
                </Button>
                <Box style={{ display: "flex", gap: 24 }}>
                    {isAuthorized && (
                        <>
                            <Button
                                to={`/${language}/cart`}
                                component={Link}
                                aria-label="Show cart items"
                                className={classes.removeButtonStandardStyling}
                                endIcon={(
                                    <Badge
                                        // eslint-disable-next-line max-len
                                        badgeContent={cart.items.length}
                                        color="primary"
                                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                        classes={{
                                            // eslint-disable-next-line max-len
                                            anchorOriginBottomRightRectangular: classes.badgePos,
                                            badge: classes.badge
                                        }}
                                    >
                                        <ShoppingCart />
                                    </Badge>
                                )}
                            />
                            <Button
                                to={`/${language}/favorites`}
                                component={Link}
                                aria-label="Show cart items"
                                className={classes.removeButtonStandardStyling}
                                endIcon={(
                                    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="25" viewBox="0 0 27 25" fill="none">
                                        <path d="M18.9317 0C16.6599 0 14.4795 1.10354 13.0563 2.84741C11.6332 1.10354 9.45277 0 7.18097 0C3.15963 0 0 3.297 0 7.49319C0 12.6431 4.43915 16.8392 11.1631 23.2153L13.0563 25L14.9495 23.2016C21.6735 16.8392 26.1126 12.6431 26.1126 7.49319C26.1126 3.297 22.953 0 18.9317 0ZM13.1869 21.1853L13.0563 21.3215L12.9257 21.1853C6.71094 15.3133 2.61126 11.4305 2.61126 7.49319C2.61126 4.76839 4.56971 2.7248 7.18097 2.7248C9.19164 2.7248 11.1501 4.07357 11.8421 5.94005H14.2836C14.9625 4.07357 16.921 2.7248 18.9317 2.7248C21.5429 2.7248 23.5014 4.76839 23.5014 7.49319C23.5014 11.4305 19.4017 15.3133 13.1869 21.1853Z" fill="#2D2D2D" />
                                    </svg>
                                )}
                            />
                        </>
                    )}
                    <Button
                        aria-label="Menu"
                        className={classes.removeButtonStandardStyling}
                    >
                        <Box onClick={handleClick} display="flex">
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="22" viewBox="0 0 28 22" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0 2C0 1.17157 0.671573 0.5 1.5 0.5H26.5C27.3284 0.5 28 1.17157 28 2C28 2.82843 27.3284 3.5 26.5 3.5H1.5C0.671573 3.5 0 2.82843 0 2Z" fill="#2D2D2D" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M0 11C0 10.1716 0.671573 9.5 1.5 9.5H26.5C27.3284 9.5 28 10.1716 28 11C28 11.8284 27.3284 12.5 26.5 12.5H1.5C0.671573 12.5 0 11.8284 0 11Z" fill="#2D2D2D" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M0 20C0 19.1716 0.671573 18.5 1.5 18.5H26.5C27.3284 18.5 28 19.1716 28 20C28 20.8284 27.3284 21.5 26.5 21.5H1.5C0.671573 21.5 0 20.8284 0 20Z" fill="#2D2D2D" />
                            </svg>
                        </Box>
                    </Button>
                </Box>
                <MobileDrawer open={Boolean(anchorEl)} onClose={handleClose} />
            </Box>
        </Box>
    )
}

export default MobileSearchArea
