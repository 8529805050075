// Need to use the React-specific entry point to import createApi
import { restoreNameForBrandOther } from "../../lib/helper/brandHelper"
import { sortModels } from "../../lib/helper/modelHelper"
import { newsArticleAdapter } from "../adapter/newsArticleAdapter"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

// Define a service using a base URL and expected endpoints
export const broditWebApi = createApi({
    reducerPath: "broditWebApi",
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/"
    }),
    endpoints: builder => ({
        getNews: builder.query({
            query: ({
                language, limit = 20, groups = [], includeFutureNews = false
            }) => {
                let params = "?"
                params += `language=${language}`
                params += `&limit=${limit}`
                params += `&includeFutureNews=${includeFutureNews}`
                params += groups.map(group => `&groups=${group}`).join("")
                return `news${params}`
            },
            transformResponse: (response, _meta, arg) => {
                const languageSpecificNews = response.map(article => ({
                    ...article,
                    id: `${arg.language}-${article.id}`,
                    originalId: article.id
                }))

                newsArticleAdapter.addMany(
                    newsArticleAdapter.getInitialState(),
                    languageSpecificNews
                )

                return languageSpecificNews
            }
        }),
        getNewsById: builder.query({
            query: ({ language, id }) => `news/${id}?language=${language}`,
            transformResponse: (response, _meta, arg) => {
                const languageSpecificNews = {
                    ...response,
                    id: `${arg.language}-${response.id}`,
                    originalId: response.id
                }

                newsArticleAdapter.addOne(
                    newsArticleAdapter.getInitialState(),
                    languageSpecificNews
                )

                return languageSpecificNews
            }
        }),
        getOrderSystemMaintenance: builder.query({
            query: () => "Users/IsOrderSystemUnderMaintenance"
        }),
        getBigNews: builder.query({
            query: () => "news/GetBigNews"
        }),
        getHeroNews: builder.query({
            query: () => "news/GetHeroNews"
        }),
        getFilesById: builder.query({
            query: id => `ProductInfo/GetProductSheets/${id}`
        }),
        getRelatedProducts: builder.query({
            query: id => `RelatedProducts/${id}`
        }),
        getModTekProducts: builder.query({
            query: id => `RelatedProducts/Modtek/${id}`
        }),
        getRelatedVideos: builder.query({
            query: id => `RelatedVideos/${id}`
        }),
        getModels: builder.query({
            query: ({ language, steerings = null }) => {
                const steeringParams = steerings
                    ? steerings.map(steering => `&steerings=${steering}`).join("")
                    : ""

                return `v1/Models?language=${language}${steeringParams}`
            },
            transformResponse: response => sortModels(response)
        }),
        getModelsForBrand: builder.query({
            query: ({ brandId, language, steerings = null }) => {
                const [restoredBrandId] = restoreNameForBrandOther([brandId])
                const steeringParams = steerings
                    ? steerings.map(steering => `&steerings=${steering}`).join("")
                    : ""

                return `v1/Brands/${restoredBrandId}/Models?language=${language}${steeringParams}`
            },
            transformResponse: response => sortModels(response)
        }),
        getYearsForModel: builder.query({
            query: ({
                models,
                brands,
                language,
                steerings
            }) => ({
                url: "ProductInfo/GetModelYears",
                method: "POST",
                body: {
                    modelIds: models,
                    brandIds: restoreNameForBrandOther(
                        brands
                    ),
                    language: language,
                    steering: steerings
                }
            })
        }),
        getReplacementProducts: builder.query({
            query: id => `ProductInfo/GetReplacementProducts?id=${id}`
        }),
        getTechnicalInformationForProduct: builder.query({
            query: id => `ProductInfo/GetProductsHtmlText?id=${id}`
        }),
        getAltNameForProducts: builder.query({
            query: id => `ProductInfo/GetAltTextForProducts?id=${id}`
        }),
        setPreferredSteering: builder.mutation({
            query: body => ({
                url: `Users/SetPreferredSteering/${body.preferredSteering}`,
                method: "POST",
                body: {
                    authToken: body.authToken
                }
            })
        }),
        logFrontendError: builder.mutation({
            query: body => ({
                url: "Error/Log",
                method: "POST",
                body: {
                    error: body.error,
                    stack: body.stack
                }
            })
        })
    })
})

export const {
    useGetNewsQuery,
    useGetNewsByIdQuery,
    useGetOrderSystemMaintenanceQuery,
    useGetBigNewsQuery,
    useGetHeroNewsQuery,
    useGetFilesByIdQuery,
    useGetRelatedProductsQuery,
    useGetRelatedVideosQuery,
    useGetModelsQuery,
    useGetModelsForBrandQuery,
    useGetYearsForModelQuery,
    useGetReplacementProductsQuery,
    useGetModTekProductsQuery,
    useGetTechnicalInformationForProductQuery,
    useSetPreferredSteeringMutation,
    useGetAltNameForProductsQuery,
    useLogFrontendErrorMutation
} = broditWebApi
