import { Desktop as DesktopTimeline, Mobile as MobileTimeline } from "./Timeline"
import useStyles from "./styles"
import Row from "../Home/components/Row"
import { mobileMenuBreakpoint } from "../../lib/helper/viewportHelper"
import { useLanguage } from "../../lib/hooks/languageHooks"
import React, { useState } from "react"
import { useMediaQuery, Box, Typography } from "@mui/material"

const About = () => {
    const [data, setData] = useState(null)
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up(mobileMenuBreakpoint))
    const classes = useStyles()
    const language = useLanguage()

    const fetchPageData = async () => {
        const response = await fetch(`/files/DynamicPages/AboutPage-${language}.json`)
        return response.json()
    }

    React.useEffect(() => {
        fetchPageData().then(result => setData(result))
    }, [language])

    const youtubeHero = () => (
        <Box className={classes.hero}>
            <iframe className={classes.youtube} src={data.youtubeEmbedUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen />
        </Box>
    )

    const topInfo = () => (
        <Box className={classes.topInfo}>
            <h2 className={classes.topTitle}>{data.topInfo.title}</h2>
            <Typography className={classes.topText}>{data.topInfo.text}</Typography>
            <Box className={classes.topAnchorWrapper}>
                <a
                    className={classes.topAnchor}
                    href={data.topInfo.url}
                >
                    {data.topInfo.anchorText}
                </a>
            </Box>
        </Box>
    )

    const story = () => (
        <Box className={classes.story}>
            <h2 className={classes.storyTitle}>{data.story.title}</h2>
            <Typography className={classes.storyText}>{data.story.text}</Typography>
        </Box>
    )

    const renderTopSection = () => (
        <>
            <Row fullWidth>
                {youtubeHero()}
            </Row>
            <Row fullWidth>
                {topInfo()}
            </Row>
            <Row fullWidth>
                <Box className={classes.storyWrapper}>
                    {story()}
                </Box>
            </Row>
        </>
    )

    return (
        <div>
            {data && renderTopSection()}
            {data && (isDesktop ? <DesktopTimeline data={data} /> : <MobileTimeline data={data} />)}
        </div>
    )
}

export default About
