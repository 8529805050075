import icon from "./icons8-external-link.svg";
import React from "react";
import { Icon } from "@mui/material";

const ExternalLinkIcon = ({ style }) => (
    <Icon style={{ ...style }}>
        <img src={icon} height={20} width={20} style={{ color: "gray" }} />
    </Icon>
)

export default ExternalLinkIcon
