import useStyles from "./styles"
import {
    useCompareCount, useCompareProducts, useIsCompareOverviewVisible, useIsOnComparePage
} from "../../redux/hook/compareHooks"
import { useProduct } from "../../redux/hook/productHooks"
import { strings as Localization } from "../../lib/Localization"
import { removeFromCompare, setShowCompareOverview, removeAllFromCompare } from "../../redux/compareProductsReducer"
import { useLanguage } from "../../lib/hooks/languageHooks"
import { mobileBreakpoint } from "../../lib/helper/viewportHelper"
import theme from "../../theme"
import React, { useEffect } from "react"
import {
    Popper, Fade, Paper, List,
    ListItemAvatar, Avatar, ListItemText,
    ListItem, Typography, IconButton, Grid, useMediaQuery, Box
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useDispatch } from "react-redux"
import { NavLink } from "react-router-dom"
import DeleteIcon from "@mui/icons-material/Delete"
import Minimize from "@mui/icons-material/Minimize"
import CompareIcon from "@mui/icons-material/Compare"

const CompareOverview = () => {
    const compareCount = useCompareCount()
    const compareProds = useCompareProducts()
    const classes = useStyles()
    const visible = useIsCompareOverviewVisible()
    const isOnComparePage = useIsOnComparePage()
    const language = useLanguage()
    const dispatch = useDispatch()
    const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint))

    const onClearClick = () => {
        dispatch(removeAllFromCompare())
    }

    const hideCompare = () => {
        dispatch(setShowCompareOverview(false))
    }

    const showCompare = () => {
        dispatch(setShowCompareOverview(true))
    }

    useEffect(() => {
        // If number of compare prods change and if we have any
        // products to compare, unhide the popup.
        dispatch(setShowCompareOverview(compareCount > 0))
    }, [compareCount])

    return (
        visible
            ? (
                <Popper
                    open={visible && !isOnComparePage}
                    transition
                    placement="bottom"
                    style={{
                        position: "fixed",
                        bottom: isMobile ? 0 : 25,
                        right: isMobile ? 0 : 25,
                        top: isMobile ? "" : "unset",
                        left: isMobile ? "" : "unset",
                        width: isMobile ? "100vw" : ""
                    }}
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper className={classes.paper} elevation={3}>
                                <Box style={{ display: isMobile ? "flex" : "none", justifyContent: "flex-end", marginBottom: "10px" }}>
                                    <Minimize onClick={hideCompare} style={{ color: "gray" }} />
                                </Box>
                                <Grid container justifyContent="space-between">
                                    <Grid item>
                                        <Typography>{Localization.compareProducts}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography>
                                            <NavLink to={`/${language}/compare`} end>
                                                {Localization.toComparison}
                                            </NavLink>
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <List className={classes.root}>
                                    {compareProds.map(prod => (
                                        <ProductEntryListItem
                                            key={`product-entry-list-${prod}`}
                                            id={prod}
                                        />
                                    ))}
                                    <ListItem>
                                        <ListItemText
                                            primary={Localization.clearAllProducts}
                                            secondary={Localization.endComparison}
                                            classes={{
                                                root: classes.clearAllListItem,
                                                primary: classes.clearAllPrimary,
                                                secondary: classes.clearAllSecondary
                                            }}
                                        />
                                        <IconButton onClick={onClearClick}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItem>
                                </List>
                            </Paper>
                        </Fade>
                    )}
                </Popper>
            )
            : (
                (isMobile && compareCount > 0) && (
                    <Paper
                        elevation={2}
                        style={{
                            padding: "8px",
                            position: "fixed",
                            bottom: 10,
                            right: 10,
                            zIndex: 99
                        }}
                        onClick={showCompare}
                    >
                        <CompareIcon style={{ color: "gray" }} />
                    </Paper>
                )
            )
    )
}

const ProductEntryListItem = ({ id }) => {
    const prod = useProduct(id)
    const dispatch = useDispatch()
    const classes = useStyles()

    const onCloseClick = () => {
        dispatch(removeFromCompare(id))
    }

    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar
                    classes={{
                        img: classes.avatarImg
                    }}
                    src={`${process.env.REACT_APP_IMAGE_URL}w120/${id}.jpg`}
                    // Todo: src={`${process.env.REACT_APP_IMAGE_URL}${id}.jpg`}
                    alt={`
                        Brodit
                        ${Localization.product}-${prod?.originalId}
                        ${Localization.isComparing}
                    `}
                    variant="square"
                />
            </ListItemAvatar>
            <ListItemText
                primary={prod?.name ?? Localization.productIsNotAvailable}
                secondary={prod?.originalId ?? id}
            />
            <IconButton onClick={onCloseClick}>
                <CloseIcon />
            </IconButton>
        </ListItem>
    )
}

export default CompareOverview
