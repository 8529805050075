/* eslint-disable no-restricted-globals */
import useStyles from "./styles"
import { formatPrice } from "../../../lib/helper/numberHelper"
import { strings as Localization } from "../../../lib/Localization"
import { deleteCartRow, editCartNote, setQuantity } from "../../../redux/cartSlice"
import { useProductsLoading } from "../../../redux/hook/productHooks"
import FavoriteButton from "../../Favorites/FavoriteButton"
import CompatibleModels from "../../Products/ProductInfo/components/CompatibleModels/CompatibleModels"
import { mobileBreakpoint } from "../../../lib/helper/viewportHelper"
import theme from "../../../theme"
import { useLanguage } from "../../../lib/hooks/languageHooks"
import {
    Accordion, AccordionDetails, AccordionSummary,
    IconButton,
    TextField, Tooltip, Typography,
    useMediaQuery,
    Skeleton
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

import classnames from "classnames"
import React from "react"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"

const MobileCartItem = ({
    id, price, currency, quantity, isModifiable, index, text, product
}) => {
    const classes = useStyles()
    const loading = useProductsLoading()
    const dispatch = useDispatch()
    const isTablet = useMediaQuery(theme.breakpoints.up(mobileBreakpoint))
    const lang = useLanguage()

    const quantityChanged = e => {
        const n = e.target.value !== "" ? e.target.value : 0
        const isNumber = !isNaN(parseFloat(n)) && isFinite(n)
        if (!isNumber) return
        const parsed = parseInt(n, 10)
        dispatch(setQuantity(parsed, index))
    }

    const deleteProduct = () => {
        dispatch(deleteCartRow(index))
    }

    const noteChanged = e => {
        const t = e.target.value
        dispatch(editCartNote(t, index))
    }

    // Product is not yet loaded..
    if (product === undefined && loading) {
        return (
            <Skeleton variant="rect" animation="wave" className={classes.mobileLoadingAnimation} />
        )
    }

    // When product is ordered but cannot be found return card with info
    if (product === undefined) {
        return (
            <div>Product undefined</div>
        )
    }

    // Product was found, return a cart
    return (
        <div className={classes.mobileCartItem}>
            <div className={classes.mobileRow}>
                <div className={classes.mobileImageWrapper}>
                    <img
                        className={classes.mobileProductImage}
                        src={`${process.env.REACT_APP_IMAGE_URL}w120/${id}.jpg`}
                        alt={product.originalId}
                    />
                </div>
                <div className={classes.mobileTitleWrapper}>
                    <div className={classes.mobileTitle}>
                        <Link className={classes.mobileLink} to={`/${lang}/product/${product.originalId}`}>{product.name}</Link>
                        <div className={classes.mobileButtonWrapper}>
                            {isModifiable
                                ? (
                                    <>
                                        <FavoriteButton productId={product.originalId} />
                                        <Tooltip title={Localization.removeItem}>
                                            <IconButton
                                                onClick={deleteProduct}
                                                className={classes.padding4}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                )
                                : null}
                        </div>
                    </div>
                    <div className={classes.mobileProductId}>{product.originalId}</div>
                    {isTablet
                        && (
                            <div className={classes.mobileDescription}>
                                {product.description}
                                <CompatibleModels
                                    compatibleModels={product.compatibleModels}
                                    handleShowMoreCompsClick={null}
                                />
                                <input
                                    style={{ marginTop: "15px", width: "300px" }}
                                    className={classes.mobileNoteInput}
                                    value={text || ""}
                                    disabled={!isModifiable}
                                    onChange={noteChanged}
                                    placeholder={Localization.note}
                                />

                            </div>
                        )}
                </div>
            </div>
            {!isTablet
                && (
                    <>
                        <div className={classnames([classes.mobileRow, classes.mobilePadded])}>
                            <Accordion className={classes.mobileAccordion}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    className={classes.mobileAccordionSummary}
                                >
                                    <p className={classes.marginNone}>{Localization.moreInformation}</p>
                                </AccordionSummary>
                                <AccordionDetails
                                    className={classnames(classes.marginNone, classes.flexColumn)}
                                >
                                    <Typography variant="h5" className={classes.heading}>
                                        {Localization.description}
                                        :
                                    </Typography>
                                    <Typography component="p" variant="caption">
                                        {product.description}
                                    </Typography>
                                    <Typography variant="h5" className={classes.heading}>
                                        {Localization.compatibleProducts}
                                        :
                                    </Typography>
                                    <CompatibleModels
                                        compatibleModels={product.compatibleModels}
                                        handleShowMoreCompsClick={null}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className={classnames([classes.mobileRow, classes.mobilePadded])} style={{ paddingTop: 0 }}>
                            <input
                                className={classes.mobileNoteInput}
                                value={text || ""}
                                disabled={!isModifiable}
                                onChange={noteChanged}
                                placeholder={Localization.note}
                            />

                        </div>
                    </>
                )}
            <div className={classnames([classes.mobileRow, classes.spaceBetween, classes.mobilePadded])}>
                <div className={classes.mobilePriceLeft}>
                    <TextField
                        inputProps={{ style: { textAlign: "center" } }}
                        className={classes.mobileTextField}
                        value={quantity}
                        disabled={!isModifiable}
                        onChange={quantityChanged}
                        size="small"
                    />
                    <div>
                        {`x ${formatPrice(price)} ${currency}`}
                    </div>
                </div>
                <div className={classes.mobilePriceRight}>
                    {`${formatPrice(price * quantity)} ${currency}`}
                </div>
            </div>
        </div>
    )
}

export default MobileCartItem
