import useStyles from "./Timeline.styles"
import Down from "./assets/arrow.svg"
import Row from "../Home/components/Row"
import React, { Fragment } from "react"
import { Box, Typography } from "@mui/material"

export const Desktop = ({ data }) => {
    const classes = useStyles()

    const timeLineRow = (item, i) => {
        const odd = i % 2 !== 0
        const oddTimelineClass = odd ? classes.oddTimeline : ""
        const oddHorizontalLineClass = odd ? classes.oddTimelineDividerHorizontalLine : ""
        const oddTextClass = odd ? classes.oddText : ""
        const lastItem = i === data.timeline.length - 1

        return (
            <Fragment key={`story-desktop-${i}`}>
                <Row style={{ backgroundColor: "#F8F8F8" }} fullWidth>
                    <Box className={`${classes.timeline} ${oddTimelineClass}`}>
                        <Box className={classes.timelineInfo}>
                            <Box>
                                <img
                                    className={classes.timelineImage}
                                    alt={item.imageText}
                                    src={`/files/ImagesStatic/About/${item.imageUrl}`}
                                />
                            </Box>
                            <Typography className={classes.text}>{item.imageText}</Typography>
                        </Box>
                        <Box className={classes.timelineDivider}>
                            <div className={classes.timelineDividerVerticalLine} />
                            <div className={`
                                ${classes.timelineDividerHorizontalLine} 
                                ${oddHorizontalLineClass}
                            `}
                            />
                        </Box>
                        <Box className={classes.timelineInfo}>
                            <h2 className={`${classes.yearTitle} ${oddTextClass}`}>{item.title}</h2>
                            <Typography className={`${classes.text} ${classes.textJustified}`}>
                                {item.text}
                            </Typography>
                        </Box>
                    </Box>
                </Row>
                <Row style={{ backgroundColor: "#F8F8F8", height: 100 }} fullWidth>
                    {!lastItem
                    && <div className={classes.timelineDividerVerticalLine} />}
                </Row>
            </Fragment>
        )
    }

    return data.timeline.map((item, i) => timeLineRow(item, i))
}

export const Mobile = ({ data }) => {
    const classes = useStyles()

    const timeLineRow = (item, i) => {
        const reverseClass = i === 0 ? classes.mobInfoReverse : ""

        return (
            <Fragment key={`story-mobile-${i}`}>
                <div className={`${classes.mobInfoWrapper} ${reverseClass}`}>
                    <Box>
                        <h2 className={classes.yearTitle}>{item.title}</h2>
                        <Typography className={classes.text}>{item.text}</Typography>
                    </Box>
                    <Box className={classes.mobImageWrapper}>
                        <img
                            className={classes.timelineImage}
                            alt={item.imageText}
                            src={`/files/ImagesStatic/About/${item.imageUrl}`}
                        />
                        <Typography className={classes.mobImageText}>{item.imageText}</Typography>
                    </Box>
                </div>
                {i < data.timeline.length - 1 && (
                    <Row style={{ backgroundColor: "#F8F8F8" }} fullWidth>
                        <img className={classes.arrow} src={Down} alt="Down arrow" />
                    </Row>
                )}
            </Fragment>
        )
    }

    return data.timeline.map((item, i) => timeLineRow(item, i))
}
