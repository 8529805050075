/* eslint-disable import/no-cycle */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-unstable-nested-components */
import useStyles from "./styles"
import { useLanguage } from "../../../lib/hooks/languageHooks"
import { strings as Localization } from "../../../lib/Localization"
import { useFilteredProducts } from "../../../redux/hook/productHooks"
import { useGetNewsByIdQuery } from "../../../redux/services/broditWebApi"
import Products from "../../Products/Products"
import ProductViewToggleButton from "../../Products/ProductViewToggleButton/ProductViewToggleButton"
import { NavigationButton } from "../../Stepper"
import Stepper from "../../Stepper/Stepper"
import StyledMarkdown from "../../StyledMarkdown"
import Thumbnail from "../../Thumbnail"
import { useParams } from "react-router-dom"
import React, { useMemo, useState } from "react"
import {
    Skeleton,
    Box, Container, Divider, Typography
} from "@mui/material"

const NewsPage = () => {
    const { place, id } = useParams()
    const [imageIndex, setImageIndex] = useState(0)
    const language = useLanguage()
    const { data, isFetching } = useGetNewsByIdQuery({
        language,
        id: `${place}/${id}`
    })
    const filter = useMemo(
        () => ({ productIds: data?.products ?? [] }),
        [data?.products]
    )
    const products = useFilteredProducts(filter)
    const classes = useStyles()

    return (
        <Box>
            <Container className={classes.container}>
                {
                    !isFetching && data
                    && (
                        <div className={classes.newsWrapper}>
                            <Box className={classes.stepperWrapper}>
                                <Typography>
                                    {/* {Localization.published}:  */}
                                    {new Date(data.dateTime).toLocaleDateString()}
                                </Typography>
                                {data.video
                                    ? (
                                        <iframe
                                            className={classes.youtubeVideo}
                                            src={data.video}
                                            title="YouTube video player"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowFullScreen
                                        />
                                    )
                                    : (
                                        <Stepper
                                            className={classes.stepper}
                                            index={imageIndex}
                                            setIndex={setImageIndex}
                                            loading={isFetching}
                                            showNavigationOnHover
                                            positionNavigationOnTop
                                            backButtonComponent={
                                                props => ((imageIndex > 0)
                                                    ? <NavigationButton {...props} />
                                                    : null)
                                            }
                                            nextButtonComponent={
                                                props => (
                                                    (
                                                        imageIndex < (data.images.length - 1)
                                                        && data.images.length > 1
                                                    )
                                                        ? <NavigationButton {...props} />
                                                        : null
                                                )
                                            }
                                            pages={
                                                data.images.map(image => {
                                                    const partsOfPath = image.split("/")
                                                    const imageId = partsOfPath[partsOfPath.length - 1]
                                                    return (
                                                        <Thumbnail
                                                            key={imageId}
                                                            href=""
                                                            src={image}
                                                            alt={`${Localization.news} ${data.originalId}`}
                                                            width="100%"
                                                            srcWidth="1500"
                                                        />
                                                    )
                                                })
                                            }
                                        />
                                    )}
                                <Box className={classes.titleBox}>
                                    <Typography classes={{ root: classes.newsTitle }} variant="h3" style={{ wordWrap: "break-word", hyphens: "auto" }}>
                                        {data.title}
                                    </Typography>
                                    <Typography classes={{ root: classes.newsPreamble }} variant="h5" style={{ wordWrap: "break-word", hyphens: "auto" }}>
                                        {data.preamble}
                                    </Typography>
                                </Box>
                                <StyledMarkdown children={data.body} />
                            </Box>
                            {
                                (data?.products ?? []).length > 0
                                && (
                                    <>
                                        <Box className={classes.noProductsBox}>
                                            <Typography>
                                                {Localization.products.toUpperCase()}
                                            </Typography>
                                            <ProductViewToggleButton />
                                        </Box>
                                        <Divider />
                                        <div className={classes.productsWrapper}>
                                            <Products products={products} />
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    )
                }
                {
                    isFetching
                    && <Skeleton />
                }
                {
                    !isFetching && !data
                    && (
                        <Box>
                            <Typography variant="h5">
                                {`${Localization.couldNotFindTheSelectedNewsArticle} "${id}".`}
                            </Typography>
                        </Box>
                    )
                }
            </Container>
        </Box>
    )
}

export default NewsPage
