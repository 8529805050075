import useStyles from "./styles"
import { useLanguage } from "../../lib/hooks/languageHooks"
import { strings as Localization } from "../../lib/Localization"
import {
    Box, Card, CardContent, CardMedia, Link, Typography
} from "@mui/material"
import React from "react"

const ContactInformation = ({ ...props }) => {
    const language = useLanguage()
    const [data, setData] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const classes = useStyles()

    const fetchContactData = async () => {
        setLoading(true)
        const response = await fetch("/files/DynamicPages/ContactPage.json")
        setLoading(false)
        return response.json()
    }

    React.useEffect(() => {
        fetchContactData().then(result => setData(result))
    }, [false])

    return (
        <>
            {!data && loading && (
                <Typography>
                    {Localization.loading}
                    ...
                </Typography>
            )}
            {data && !loading && (
                <Box {...props}>
                    <Typography variant="h4">
                        {data?.content.generalText.filter(x => x.lang === language)[0].text}
                    </Typography>
                    <Card className={classes.map}>
                        <CardMedia className={classes.cardMedia}>
                            <iframe
                                title="contact-information-map"
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d16650.30437209762!2d14.496117!3d58.557016000000004!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465bbb78ed2af671%3A0xdf8c87814c38f811!2sGes%C3%A4llv%C3%A4gen%208%2C%20546%2033%20Karlsborg!5e0!3m2!1ssv!2sse!4v1638873030781!5m2!1ssv!2sse"
                                className={classes.mapFrame}
                                allowFullScreen=""
                                loading="lazy"
                            />
                        </CardMedia>
                        <CardContent>
                            <Typography>{data?.content.generalContactInfo.company}</Typography>
                            <Typography>{data?.content.generalContactInfo.street}</Typography>
                            <Typography>{data?.content.generalContactInfo.zipcode}</Typography>

                            <Typography>
                                {`${Localization.phone} `}
                                {data?.content.generalContactInfo.phone}
                            </Typography>
                            <Typography>
                                {` ${Localization.fax} `}
                                {data?.content.generalContactInfo.fax}
                            </Typography>
                            <Typography>
                                {` ${Localization.email} `}
                                <Link href={`mailto:${data?.content.generalContactInfo.email}`}>
                                    {data?.content.generalContactInfo.email}
                                </Link>
                            </Typography>
                        </CardContent>
                    </Card>
                    <Box className={classes.section}>
                        {data?.content.sections.map(section => (
                            <Box
                                key={section.title.map(title => title.text).join()}
                            >
                                <Typography
                                    variant="h5"
                                    className={classes.margin10}
                                >
                                    {section.title.filter(x => x.lang === language)[0].text}
                                </Typography>

                                {
                                    section.list.map(item => (
                                        <Card
                                            className={classes.card}
                                            key={item.name}
                                        >
                                            <CardContent />
                                            <CardMedia
                                                component="img"
                                                image={`/files/ImagesStatic/${item.image}`}
                                                alt={item.name}
                                                className={classes.alignCenter}
                                            />
                                            <CardContent>
                                                <Typography>
                                                    {
                                                        item.roles
                                                            ? item.roles.map(x => (
                                                                x.names.filter(y => (
                                                                    y.lang === language
                                                                ))[0].text))
                                                            : null
                                                    }
                                                </Typography>
                                                <Typography variant="body2">
                                                    {`${Localization.name} ${item.name}`}
                                                </Typography>
                                                <Typography variant="body2">
                                                    {`${Localization.phone}: ${item.phone}`}

                                                </Typography>
                                                <Typography variant="body2">
                                                    {`${Localization.email}: `}
                                                    <Link href={`mailto:${item.email}`}>
                                                        {item.email}
                                                    </Link>
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    ))
                                }
                            </Box>
                        ))}
                    </Box>
                </Box>
            )}
        </>
    )
}

export default ContactInformation
