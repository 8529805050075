/* eslint-disable import/no-cycle */
import { useProduct } from "../../../../redux/hook/productHooks"
import MissingFavoriteProductCard from "../MissingFavoriteProductCard"
import ProductCard from "../ProductCard"
import { useLanguage } from "../../../../lib/hooks/languageHooks"
import { changeSelectedModTek } from "../../../../redux/userSlice"
import { useSelectedModTek } from "../../../../redux/hook/userHooks"
import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"

import {
    Box,
    Button,
    Typography
} from "@mui/material"
import { useDispatch } from "react-redux"

const ProductGallery = ({
    list, grid, persistScrollPosition, restorationRef, hidden
}) => {
    const products = list?.map(id => useProduct(id)) || []
    return (
        <Box sx={{
            maxHeight: "400px", display: (hidden === true) ? "none" : "block"
        }}
        >
            {products.map((p, i) => (
                <ProductCard
                    key={p?.id}
                    product={p}
                    handleShowMoreCompsClick={null}
                    grid={grid}
                    onSelect={persistScrollPosition}
                    restorationRef={restorationRef[i]}
                    modTek={p}
                />
            ))}
        </Box>
    )
}

const ModTekSelector = ({
    restorationRef,
    grid,
    missingProducts,
    modTek = {},
    modTekId,
    persistScrollPosition
}) => {
    const dispatch = useDispatch()
    const language = useLanguage()
    const modTekTitles = modTek?.title?.[language]
    const titles = modTekTitles
    const index = {}
    const [activeButton, setActiveButton] = useState("")

    titles.forEach((key, i) => { index[key] = i })// { dock: 0, power: 1, accessories: 2 }
    const state = useSelectedModTek()

    const chooseStep = i => {
        setActiveButton(modTek?.title?.en[i])
        dispatch(changeSelectedModTek({ id: modTekId, step: modTek?.title?.en[i] }))
    }

    useEffect(() => {
        if (state.step !== "") {
            chooseStep(modTek.title.en.indexOf(state.step))
        } else {
            chooseStep(modTek?.title?.en.indexOf(modTek?.title?.en[0]))
        }
    }, [])

    return (
        <Box>
            {
                missingProducts?.map(id => (
                    <MissingFavoriteProductCard key={id} productId={id} grid={grid} />
                ))
            }
            <Box display="flex" flexDirection="row" justifyContent="space-around">
                {
                    titles.map((title, ind) => (
                        <Box
                            style={{ cursor: "pointer" }}
                            onClick={() => chooseStep(ind)}
                            key={title}
                        >
                            <Button
                                disabled={
                                    (modTek.title.en[titles.indexOf(title)] !== activeButton)
                                }
                                component={Link}
                                color="primary"
                                variant="contained"
                                to="#"
                                size="small"
                                maxWidth="82px"
                            >
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    width="82px"
                                    height="41px"
                                >
                                    <Typography variant="caption">{title?.toUpperCase()}</Typography>
                                </Box>
                            </Button>
                        </Box>
                    ))
                }
            </Box>
            <Box sx={{ overflow: "auto", maxHeight: "400px" }}>
                {modTek?.title?.en.map(t => (
                    <ProductGallery
                        list={modTek?.categories.find(category => category.id === t)?.products}
                        key={t}
                        restorationRef={restorationRef}
                        grid={grid}
                        missingProducts={missingProducts}
                        modTek={modTek}
                        modTekId={modTekId}
                        persistScrollPosition={persistScrollPosition}
                        hidden={t !== activeButton}
                    />
                ))}
            </Box>
        </Box>
    )
}

export default ModTekSelector
