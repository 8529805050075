import useStyles from "./styles"
import ArrowNext from "./assets/arrow-next.svg"
import AppMenuItemComponent from "../AppMenuItemComponent/AppMenuItemComponent"
import { useLanguage } from "../../../lib/hooks/languageHooks"
import {
    Box, Typography
} from "@mui/material"
import React, { useState, useEffect } from "react"

// varför går det inte att skicka props id, namn och collections som ett enda objekt? det blir undefined av någon okänd anledning i vissa fall på desktop
const AppMenuItem = ({
    id, name, collections, onItemClick, selectCollection, level, history
}) => {
    const collection = { id, name, collections }
    const classes = useStyles()
    const isExpandable = collections && collections.length > 0
    const [open, setOpen] = useState(false)
    const language = useLanguage()
    const handleClick = () => {
        setOpen(true)
        selectCollection(collection.id, level)
    }

    useEffect(() => {
        if (!history?.some(c => c.id === id)) setOpen(false)
    }, [history])

    const MenuItemRoot = (
        <Box onClick={!isExpandable ? () => onItemClick(id) : () => handleClick()}>
            <AppMenuItemComponent
                className={classes.menuItem}
                link={isExpandable ? null : `/${language}/filter`}
            >
                <div className={classes.categoryArea}>
                    <Typography variant="caption" className={`${classes.catalog} ${open ? classes.bold : ""}`}>{name}</Typography>
                    {isExpandable && <img src={ArrowNext} alt="Arrow next" />}
                    {/* <img className={!isExpandable && classes.hidden} src={ArrowNext} alt="Arrow next" /> */}
                </div>
            </AppMenuItemComponent>
        </Box>
    )

    return MenuItemRoot
}

export default AppMenuItem
