import DealerSearchFilter from "./DealerSearchFilter"
import useStyles from "./styles"
import { useLanguage } from "../../lib/hooks/languageHooks"
import { strings as Localization } from "../../lib/Localization"
import CustomSolutions from "../../assets/customizedSolutions.png"
import { desktopBreakpoint } from "../../lib/helper/viewportHelper"
import {
    Box, Card, CardContent, Checkbox, Link, Typography,
    useMediaQuery
} from "@mui/material"
import React, { useState, useEffect } from "react"
import classnames from "classnames"

const SearchDealer = ({ ...props }) => {
    const language = useLanguage()
    const [data, setData] = useState([])
    const [dealersByCountries, setDealersByCountries] = useState([])
    const classes = useStyles()
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up(desktopBreakpoint))

    const [filter, setFilter] = useState({
        place: null,
        webshop: null,
        country: null
    })

    const fetchDealerData = async () => {
        const response = await fetch("/files/DynamicPages/Resellers.json")
        return response.json()
    }

    const fetchDealerByCountry = async () => {
        const response = await fetch("/files/DynamicPages/DealersByCountry.json")
        return response.json()
    }

    useEffect(() => {
        setData([])
        setDealersByCountries([])
        setFilter({
            place: null,
            webshop: null,
            country: null
        })

        fetchDealerData().then(result => {
            result.sort((a, b) => a - b)
            setData(result.filter(x => x.customerNr !== ""))
        })

        fetchDealerByCountry().then(result => {
            result.sort((a, b) => a - b)
            setDealersByCountries(result)
        })
    }, [language])

    return (
        <Box style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
            <img
                src={CustomSolutions}
                alt=""
                style={{
                    width: "100%",
                    height: "auto"
                }}
            />
            <Box alignContent="space-between" {...props} style={{ paddingTop: isDesktop ? "80px" : "0px" }}>
                <Box style={{
                    backgroundColor: isDesktop ? "rgb(250, 250, 250)" : "#FFF", display: "flex", flexDirection: "column", alignItems: isDesktop ? "flex-start" : "center", paddingTop: isDesktop ? "0px" : "20px"
                }}
                >
                    <Typography variant="h2" className={classes.blackH2} style={{ width: "fit-content" }}>
                        {Localization.findLocalRetailer}
                    </Typography>
                    <Box className={classes.dealerSearchFilterWrapper}>
                        <DealerSearchFilter
                            data={data}
                            countryDealers={dealersByCountries}
                            filter={filter}
                            onChangeCountryDealers={
                                (event, value) => {
                                    setFilter({ place: null, webshop: null, country: value })
                                }
                            }
                            onChangeDealerLocations={
                                (event, value) => {
                                    setFilter({ place: value, webshop: null, country: null })
                                }
                            }
                        />
                    </Box>
                </Box>
                {language === "sv" && !isDesktop && (
                    <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Typography style={{ display: "flex", alignItems: "center" }}>{Localization.onlyOnlineShops}</Typography>
                        <Checkbox
                            id="dealerWebshops"
                            checked={filter.webshop}
                            onChange={(event, value) => {
                                setFilter({ place: null, webshop: value, country: null })
                            }}
                        />
                    </Box>
                )}
                <Box className={classes.resultGrid} id="dealers">
                    <Box style={{
                        display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 30, justifyContent: isDesktop ? "flex-start" : "center"
                    }}
                    >
                        {
                            data && filter.webshop !== true
                                ? data.filter(company => (
                                    filter.place !== undefined && company.place === filter.place)
                                    || (filter.webshop !== undefined && company.webShop === filter.webshop))
                                    .map(company => {
                                        const address = company.visitAddress
                                            ? company.visitAddress
                                            : company.postalAddress
                                        return (
                                            <Card className={classes.resultCard} key={company.customerNr}>
                                                <CardContent style={{ padding: "0px" }}>
                                                    <Box style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "space-between",
                                                        minHeight: "100px"
                                                    }}
                                                    >
                                                        <Typography>
                                                            {
                                                                company.commonName !== ""
                                                                    ? company.commonName
                                                                    : company.organization
                                                            }
                                                        </Typography>
                                                        {address && (
                                                            <Typography>
                                                                {address}
                                                                ,
                                                                {" "}
                                                                {company.zip}
                                                            </Typography>
                                                        )}
                                                        <Typography className={classnames(classes.bold, classes.underline)}>
                                                            {company.phone}
                                                        </Typography>
                                                        <Link
                                                            style={{ textDecoration: "none" }}
                                                            href={`http://${company.website}`}
                                                            target="_blank"
                                                            rel="noopener"
                                                        >
                                                            <Typography className={classnames(classes.bold, classes.underline)}>
                                                                {company.website}
                                                            </Typography>
                                                        </Link>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        )
                                    }) : null
                        }
                    </Box>
                    {
                        filter.country ? dealersByCountries.filter(
                            x => x.Country === filter.country
                        ).map(company => (
                            <Card className={classes.resultCard} key={company.Company}>
                                <CardContent style={{ padding: 0 }}>
                                    <Box style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        minHeight: "100px"
                                    }}
                                    >
                                        <Typography>{company.Company}</Typography>
                                        <Typography>{company.Country}</Typography>
                                        <Typography className={classnames(classes.bold, classes.underline)}>
                                            {company.Phone}
                                        </Typography>
                                        <Link
                                            style={{ textDecoration: "none" }}
                                            href={company.Webpage}
                                            target="_blank"
                                            rel="noopener"
                                        >
                                            <Typography className={classnames(classes.bold, classes.underline)}>{company.Webpage}</Typography>
                                        </Link>
                                    </Box>
                                </CardContent>
                            </Card>
                        )) : null
                    }
                    {isDesktop && language === "sv" && <Box><Typography variant="h2" className={classes.blackH2} style={{ marginTop: "60px" }}>{Localization.shopOnline}</Typography></Box>}
                    <Box style={{
                        display: "flex", flexDirection: isDesktop ? "row" : "column", marginBottom: isDesktop ? "80px" : "8px", marginTop: isDesktop ? "30px" : "8px", gap: 30
                    }}
                    >
                        {(((data && filter.webshop === true) || isDesktop) && language === "sv")
                            ? data.filter(company => (company.webShop !== ""))
                                .map(company => {
                                    const address = company.visitAddress
                                        ? company.visitAddress
                                        : company.postalAddress
                                    return (
                                        <Card className={classes.resultCard} key={company.customerNr}>
                                            <CardContent style={{ padding: "0px", paddingBottom: "0px" }}>
                                                <Box style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "space-between",
                                                    minHeight: "100px"
                                                }}
                                                >
                                                    <Typography>
                                                        {
                                                            company.commonName !== ""
                                                                ? company.commonName
                                                                : company.organization
                                                        }
                                                    </Typography>
                                                    {address && (
                                                        <Typography>
                                                            {address}
                                                            ,
                                                            {" "}
                                                            {company.zip}
                                                        </Typography>
                                                    )}
                                                    <Typography className={classnames(classes.bold, classes.underline)}>
                                                        {company.phone}
                                                    </Typography>
                                                    <Link
                                                        style={{ textDecoration: "none" }}
                                                        href={`http://${company.website}`}
                                                        target="_blank"
                                                        rel="noopener"
                                                    >
                                                        <Typography className={classnames(classes.bold, classes.underline)}>
                                                            {company.website}
                                                        </Typography>
                                                    </Link>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    )
                                }) : null}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default SearchDealer
