import {
    microBreakpoint, mobileBreakpoint, mobileMenuBreakpoint, tabletBreakpoint
} from "../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    footer: {
        display: "flex",
        flexWrap: "wrap",
        gap: "30px",
        width: "100%",
        justifyContent: "center",
        backgroundColor: "#F8F8F8",
        paddingBottom: "65px",
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            paddingBottom: "30px"
        }
    },
    footerGrid: {
        display: "grid",
        gridTemplateColumns: "206px 170px 200px 235px 155px 110px",
        gap: "20px",
        [theme.breakpoints.down(tabletBreakpoint)]: {
            gridTemplateColumns: "150px 170px 200px 170px 130px 110px"
        },
        [theme.breakpoints.down(mobileMenuBreakpoint)]: {
            gridTemplateColumns: "150px 150px 150px 150px",
            gridGap: "25px 25px",
            padding: "20px"
        },
        [theme.breakpoints.down(mobileBreakpoint)]: {
            gridTemplateColumns: "150px 150px",
            gridGap: "25px 25px",
            padding: "20px"
        },
        [theme.breakpoints.down(microBreakpoint)]: {
            gridTemplateColumns: "150px"
        }
    },
    logotypeWrapper: {
        [theme.breakpoints.down(tabletBreakpoint)]: {
            display: "none"
        }
    },
    logotype: {
        width: "100%",
        [theme.breakpoints.down(tabletBreakpoint)]: {
            width: "150px"
        }
    },
    gridItem: {
        display: "flex",
        flexDirection: "column"
    },
    addressWrapper: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "15px",
        fontFamily: "Arial",
        fontSize: "17px",
        fontWeight: 400,
        lineHeight: "24px",
        letterSpacing: "0.5px",
        textAlign: "left",
        textDecoration: "none",
        color: "#2D2D2D",
        [theme.breakpoints.down(tabletBreakpoint)]: {
            fontSize: 14
        }
    },
    text: {
        fontFamily: "Arial",
        fontSize: "17px",
        fontWeight: 400,
        lineHeight: "24px",
        letterSpacing: "0.5px",
        textAlign: "left",
        textDecoration: "none",
        color: "#2D2D2D",
        [theme.breakpoints.down(tabletBreakpoint)]: {
            fontSize: "14px"
        }
    },
    anchor: {
        fontFamily: "Arial",
        fontSize: "17px",
        fontWeight: 400,
        lineHeight: "28px",
        letterSpacing: "0.5px",
        textAlign: "left",
        textDecoration: "none",
        color: "#2D2D2D",
        [theme.breakpoints.down(tabletBreakpoint)]: {
            fontSize: "14px"
        }
    },
    anchorButtonStyle: {
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "16px",
        letterSpacing: "0.5px",
        padding: "10px 20px 10px 20px",
        borderRadius: "4px",
        textAlign: "center",
        textDecoration: "none",
        color: "#2D2D2D",
        backgroundColor: "#F09057",
        marginTop: "10px",
        maxWidth: "60%",
        transition: "all 0.1s ease-in",
        "&:hover": {
            backgroundColor: "#ef7830"
        }
    },
    addressAnchorWrapper: {
        display: "flex",
        flexWrap: "wrap",
        gap: "6px"
    },
    innerAnchorWrapper: {
        flex: "0 0 100%"
    },
    addressAnchor: {
        fontFamily: "Arial",
        fontWeight: 700,
        color: "#2D2D2D",
        fontSize: "17px",
        lineHeight: 1.1,
        letterSpacing: "0.5px",
        textAlign: "left",
        textDecoration: "none",
        paddingBottom: "1px",
        "&:first-child": {
            marginBottom: "5px"
        },
        [theme.breakpoints.down(tabletBreakpoint)]: {
            fontSize: "14px"
        }
    },
    groupTitle: {
        fontFamily: "Arial",
        fontSize: "17px",
        fontWeight: 700,
        lineHeight: "24px",
        marginBottom: "1px",
        [theme.breakpoints.down(tabletBreakpoint)]: {
            fontSize: "14px"
        }
    }
}))
