import useStyles from "./styles"
import { useLanguage } from "../../../../lib/hooks/languageHooks"
import { strings as Localization } from "../../../../lib/Localization"
import React from "react"
import { NavLink } from "react-router-dom"

const Contact = () => {
    const language = useLanguage()
    const classes = useStyles()

    return (
        <div className={classes.contact}>
            <div className={classes.contentWrapper}>
                <h2 className={classes.title}>
                    {Localization.homeSection5Title}
                </h2>
                <span className={classes.text}>
                    {Localization.homeSection5Text}
                </span>
                <NavLink className={classes.anchorButtonStyle} to={`/${language}/static/contacts`} end>
                    {Localization.contactBrodit}
                </NavLink>
            </div>
        </div>
    )
}

export default Contact
