import useStyles from "./styles";
import CsvReader from "./CsvReader/CsvReader";
import image from "../../assets/orderByFileExample.jpg";
import { strings as Localization } from "../../lib/Localization";
import { batchAddToCart } from "../../redux/cartSlice";
import { setDialogMessage } from "../../redux/userMessageSlice";
import { useLanguage } from "../../lib/hooks/languageHooks";
import { checkMonitorArticles } from "../../lib/helper/userHelper";
import { useSelector, useDispatch } from "react-redux";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { Alert, Typography, Box } from "@mui/material";

import React, { useState } from "react"

const OrderUpload = () => {
    const classes = useStyles();
    const cart = useSelector(state => state.cart);
    const dispatch = useDispatch();
    const lang = useLanguage();
    const [loading, setLoading] = useState(false);

    const callback = async (readSuccess, array) => {
        if (cart.items.length > 0) {
            dispatch(setDialogMessage(
                <Alert severity="warning">{Localization.clearYourCartFirst}</Alert>
            ));
            return;
        }
        if (readSuccess) {
            checkMonitorArticles(lang, array.map(x => x.article), result => {
                const cartArticles = array.filter(x => !result.includes(x.article));
                dispatch(batchAddToCart(cartArticles));

                if (result.length > 0) {
                    dispatch(setDialogMessage(
                        <ArticlesPartiallyLoadedMessage
                            loadedArticles={cartArticles}
                            failedArticles={result}
                        />
                    ));
                } else {
                    dispatch(setDialogMessage(
                        <Alert severity="success">{Localization.orderWasImported}</Alert>
                    ));
                }
            }, setLoading);
        } else {
            dispatch(setDialogMessage(
                <Alert severity="error">{Localization.errorReadingOrderFile}</Alert>
            ));
        }
    }

    return (
        <main className={classes.content}>
            <Typography variant="h3" className={classes.capitalize} gutterBottom>{Localization.orderByFile}</Typography>
            <FileCopyIcon />
            <Box style={{ height: 10, flexGrow: 1 }} />
            <Box style={{ display: "flex", flexDirection: "row" }}>
                <Box>
                    <img src={image} alt="instruction" />
                </Box>
                <Box style={{ marginLeft: 20 }}>
                    <Typography gutterBottom>{Localization.orderByFileInfoParagraph1}</Typography>
                    <Typography gutterBottom>{Localization.orderByFileInfoParagraph2}</Typography>
                    <Box style={{ marginTop: 20, width: "50%" }}>
                        <CsvReader callbackFn={callback} />
                    </Box>
                </Box>
            </Box>
        </main>
    )
}

function ArticlesPartiallyLoadedMessage({ loadedArticles, failedArticles }) {
    const getNotIncludedMessage = notInMonitor => (
        <Box>
            <Typography>
                {Localization.notIncludedArticlesMessage}
                :
            </Typography>
            <br />
            {notInMonitor.map(article => (<Typography>{article}</Typography>))}
        </Box>
    )

    return (
        <Box>
            {
                loadedArticles.length > 0
                && (
                    <Alert severity="success" variant="outlined">
                        <Typography>{Localization.orderWasImported}</Typography>
                    </Alert>
                )
            }
            <br />
            <Alert severity="warning">
                {getNotIncludedMessage(failedArticles)}
            </Alert>
        </Box>
    )
}

export default OrderUpload
