import { tabletBreakpoint } from "../../lib/helper/viewportHelper"
import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        fontFamily: theme.fontFamily,
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3)
    },
    section: {
        display: "grid",
        background: "#f3f3f3",
        gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))"
    },
    card: {
        margin: "10px"
    },
    cardMedia: {
        margin: "20px"
    },
    map: {
        display: "flex",
        justifyContent: "flex-start",
        marginBottom: "40px",
        [theme.breakpoints.down(tabletBreakpoint)]: {
            flexDirection: "column"
        }
    },
    mapContent: {
        alignSelf: "center",
        [theme.breakpoints.down(tabletBreakpoint)]: {
            alignSelf: "left"
        }
    },
    mapFrame: {
        width: "400px",
        height: "250px",
        border: 0,
        [theme.breakpoints.down(tabletBreakpoint)]: {
            width: "100%",
            height: "auto"
        }
    },
    margin10: {
        margin: "10px"
    },
    alignCenter: {
        alignSelf: "center"
    }
}))
