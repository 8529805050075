/* eslint-disable react/jsx-filename-extension */
import App from "./App"
import { store } from "./redux/store"
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import {
    BrowserRouter as Router, Route, Routes, Navigate
} from "react-router-dom"

const AppWrapper = () => <App />

const DefaultRoute = () => <Navigate to="/en" replace />

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <Routes>
                <Route
                    path="/:locale/*"
                    element={<AppWrapper />}
                />
                <Route path="/*" element={<DefaultRoute />} />
            </Routes>
        </Router>
    </Provider>,
    document.getElementById("root")
)
