/* eslint-disable no-restricted-globals */
import useStyles from "./styles"
import { formatAmount, formatPrice } from "../../../lib/helper/numberHelper"
import { strings as Localization } from "../../../lib/Localization"
import { deleteCartRow, editCartNote, setQuantity } from "../../../redux/cartSlice"
import { useProduct, useProductsLoading } from "../../../redux/hook/productHooks"
import FavoriteButton from "../../Favorites/FavoriteButton"
import Banner from "../../Products/Banner/Banner"
import CompatibleModels from "../../Products/ProductInfo/components/CompatibleModels/CompatibleModels"
import {
    Avatar, Box, IconButton, Paper, TextField, Tooltip, Typography,
    Skeleton
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"

import classnames from "classnames"
import React from "react"
import { useDispatch } from "react-redux"

const ProductCardOrder = ({
    id, price, currency, quantity, deliveredQuantity, isModifiable, index, text, product
}) => {
    const classes = useStyles()
    const loading = useProductsLoading()
    const dispatch = useDispatch()

    const quantityChanged = e => {
        const n = e.target.value !== "" ? e.target.value : 0
        const isNumber = !isNaN(parseFloat(n)) && isFinite(n)
        if (!isNumber) return
        const parsed = parseInt(n, 10)
        dispatch(setQuantity(parsed, index))
    }

    const deleteProduct = () => {
        dispatch(deleteCartRow(index))
    }

    const noteChanged = e => {
        const t = e.target.value
        dispatch(editCartNote(t, index))
    }

    // Product is not yet loaded..
    if (product === undefined && loading) {
        return (
            <Skeleton variant="rect" animation="wave" className={classes.loadingAnimation} />
        )
    }

    // When product is ordered but cannot be found return card with info
    if (product === undefined) {
        return (
            <Paper className={classes.root}>
                <Box className={classes.flexRow}>
                    <Box className={classes.flexItem}>
                        <Box className={classes.flexColumn}>
                            <Avatar
                                src={`${process.env.REACT_APP_IMAGE_URL}w120/${id}.jpg`}
                                // Todo: src={`${process.env.REACT_APP_IMAGE_URL}${id}.jpg?height=100`}
                                variant="square"
                                classes={{
                                    root: classes.avatarLarge,
                                    img: classes.avatarImg
                                }}
                            />
                            <Typography variant="caption">
                                {Localization.itemNumber}
                                {" "}
                                {id}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className={classnames(classes.flexItem, classes.basis20)}>
                        <Typography variant="caption">
                            {Localization.thisProductIsNotAvailableOrder}
                        </Typography>
                        <Box className={classes.flexColumn} />
                    </Box>
                    <Box className={classnames(classes.bottomContent, classes.flexItem)}>
                        <Typography>{text}</Typography>
                    </Box>
                    <Box className={classnames(classes.bottomContent, classes.flexItem)}>
                        <Typography>{formatAmount(quantity)}</Typography>
                    </Box>
                    {deliveredQuantity !== undefined ? (
                        <Box className={classnames(classes.bottomContent, classes.flexItem)}>
                            <Typography>{formatAmount(deliveredQuantity) || "0"}</Typography>
                        </Box>
                    ) : (null)}
                    <Box className={classnames(classes.bottomContent, classes.flexItem)}>
                        <Typography>{formatPrice(price)}</Typography>
                    </Box>
                    <Box className={classnames(classes.bottomContent, classes.flexItem)}>
                        <Typography>{formatPrice(price * quantity)}</Typography>
                    </Box>
                    <Box className={classnames(classes.bottomContent, classes.flexItem)}>
                        {isModifiable
                            ? (
                                <>
                                    <FavoriteButton productId={id} />
                                    <Tooltip title={Localization.removeItem}>
                                        <IconButton
                                            onClick={deleteProduct}
                                            className={classes.padding4}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            )
                            : null}
                    </Box>
                </Box>
            </Paper>
        )
    }

    return (
        <Paper className={classes.root}>
            <Box className={classes.flexRow}>
                <Box className={classnames(classes.flexRow, classes.flexItem)} style={{ gap: "10px" }}>
                    <div style={{ display: "flex", height: "100%" }}>
                        {product.isNew && <Banner type="new" />}
                        {product.comingSoon && <Banner type="comingSoon" />}
                        {!product.comingSoon && !product.isNew && <Banner type="blank" />}
                        { /* <Banner type='bestseller' /> */ /* Not in first iteration.. */}
                    </div>
                    <Avatar
                        src={`${process.env.REACT_APP_IMAGE_URL}w120/${id}.jpg`}
                        alt={`Brodit ${Localization.product}-${product.originalId}`}
                        // todo: w120 path for src={`${process.env.REACT_APP_IMAGE_URL}${id}.jpg?height=100`}
                        variant="square"
                        classes={{
                            root: classes.avatarLarge,
                            img: classes.avatarImg
                        }}
                    />
                </Box>
                <Box className={classes.flexItem}>
                    <Box className={classes.flexColumn} style={{ display: "flex", justifyContent: "center", height: "100%" }}>
                        <Typography variant="caption">
                            {product.originalId}
                        </Typography>
                    </Box>
                </Box>
                <Box className={classnames(classes.flexItem, classes.basis20)}>
                    <Typography variant="h5" className={classes.heading}>
                        {product.name}
                    </Typography>
                    <Typography component="p" variant="caption">
                        {product.description}
                    </Typography>
                    <CompatibleModels
                        compatibleModels={product.compatibleModels}
                        handleShowMoreCompsClick={null}
                    />
                </Box>
                <Box className={classnames(
                    classes.bottomContent,
                    classes.flexItem,
                    classes.marginRight24
                )}
                >
                    <TextField
                        value={text || ""}
                        disabled={!isModifiable}
                        variant="standard"
                        onChange={noteChanged}
                    />
                </Box>
                <Box className={classnames(classes.bottomContent, classes.flexItem)}>
                    <TextField
                        inputProps={{ style: { textAlign: "center" } }}
                        className={classes.textField}
                        value={quantity}
                        disabled={!isModifiable}
                        onChange={quantityChanged}
                        size="small"
                        variant="standard"
                    />
                </Box>
                {deliveredQuantity !== undefined ? (
                    <Box className={classnames(classes.bottomContent, classes.flexItem)}>
                        <Typography>{formatAmount(deliveredQuantity) || "0"}</Typography>
                    </Box>
                ) : (null)}
                <Box className={classnames(classes.bottomContent, classes.flexItem)}>
                    <Typography>
                        {formatPrice(price)}
                        {" "}
                        {currency}
                    </Typography>
                </Box>
                <Box className={classnames(classes.bottomContent, classes.flexItem)}>
                    <Typography>
                        {formatPrice(price * quantity)}
                        {" "}
                        {currency}
                    </Typography>
                </Box>
                <Box className={classnames(classes.bottomContent, classes.flexItem)}>
                    {isModifiable
                        ? (
                            <>
                                <FavoriteButton productId={product.originalId} />
                                <Tooltip title={Localization.removeItem}>
                                    <IconButton
                                        onClick={deleteProduct}
                                        className={classes.padding4}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )
                        : null}
                </Box>
            </Box>
        </Paper>
    )
}

export default ProductCardOrder
